<template>
  <div>
    <b-card no-body>
      <div class="text-center">
        <h3 class="mt-1">ประวัติการชำระเงิน</h3>
      </div>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- sorting  -->
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="2"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">none</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
          <!-- filter -->
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        style="font-size: 13px"
        align="center4"
      >
        <template #cell(mont)="data">
          <div @click="model_detail(data.item)">
            <div v-if="data.item.type == 'packages'">
              <div v-if="data.item.package_category == 'monthly'">
                {{
                  $moment(data.item.data_detail.collect_month_start).format(
                    "MM/YYYY"
                  )
                }}
              </div>
              <div v-else>
                {{
                  $moment(data.item.data_detail.collect_year_start).format(
                    "MM/YYYY"
                  )
                }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(description)="data">
          <div @click="model_detail(data.item)" style="width: 280px">
            {{ data.item.description.substring(0, 41) }}.....
          </div>
        </template>
        <template #cell(viwe)="data">
          <div @click="model_detail(data.item)">
            <p style="color: #2bc85e">Viwe</p>
          </div>
        </template>
        <template #cell(total_price)="data">
          <div @click="model_detail(data.item)">
            ฿{{
              new Intl.NumberFormat("en-IN").format(data.item.total_price)
            }}.00
          </div>
        </template>
        <template #cell(create_datetime)="data">
          <div @click="model_detail(data.item)">
            {{ $moment(data.item.create_datetime).format("DD/MM/YYYY") }}
          </div>
        </template>
        <template #cell(tax)="data">
         <div class="mb-1" v-if="data.item.link_tax_invice">
          <a  :href="data.item.link_tax_invice" target="_blank" >ดาวน์โหลด</a>
         </div>
        </template>
        <template #cell(status)="data">
          <!-- {{ data.item.type}} -->
          <div @click="model_detail(data.item)">
            <span v-if="data.item.type === 'product'">
              <p
                style="color: #2bc85e"
                class="badge-glow"
                v-if="data.item.status === 'successful'"
              >
                ชำระเงินแล้ว
              </p>
            </span>

            <span v-else>
              <p
                style="color: #5b82db"
                class="badge-glow"
                v-if="
                  data.item.status === 'pending' &&
                  data.item.schedules_status === 'running'
                "
              >
                รอชำระค่าบริการ
              </p>
              <p
                style="color: #f45252"
                class="badge-glow"
                v-else-if="
                  data.item.status === 'deleted' &&
                  data.item.schedules_status === 'deleted'
                "
              >
                ยกเลิกการเรียกเก็บ
              </p>

              <p
                style="color: #f45252"
                class="badge-glow"
                v-else-if="data.item.status === 'old'"
              >
                ยกเลิกการเรียกเก็บ
              </p>

              <p style="color: #2bc85e" class="badge-glow" v-else>
                ชำระเงินแล้ว
              </p>
            </span>
          </div>
        </template>
        <!-- <template #cell(tax)="data">
          <p style="color: #2bc85e" @click="model_detail(data.item)">
            รายละเอียด
          </p>
        </template> -->
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <hr />
    <b-modal
      id="modal-xl"
      ok-only
      ok-title="ปิด"
      centered
      size="lg"
      title="รายละเอียดการชำระเงิน"
      v-model="model_show"
    >
      <div v-if="product_type === 'product'">
        หมายเลขคำสั่งซื้อ: {{ product.omise_transactions_id }} <br />{{
          product.create_datetime
        }}
        <div style="text-align: right; margin-top: -20px">
          <b-badge
            variant="light-success"
            style="padding: 10px"
            v-if="product.status === 'successful'"
          >
            สั่งซื้อสำเร็จ
          </b-badge>
          <b-badge
            variant="light-danger"
            style="padding: 10px"
            v-if="product.status === 'failed'"
          >
            ยกเลิกแล้ว
          </b-badge>
        </div>
        <hr />

        <div class="blue box ex2">
          <div class="coral item">
            <span class="pink item">
              <div>
                <b-img :src="product.data_detail.image" width="120px" />
                <span style="font-size: 17px">
                  {{ product.data.name }}
                </span>
                <div
                  style="font-size: 12px"
                  v-html="product.data.detail"
                ></div>
              </div>
            </span>
          </div>
          <div class="coral item">
            <span class="pink item">
              <div>
                <table class="mt-1">
                  <tr>
                    <th align="center">ราคาต่อหน่วย</th>

                    <th align="center">รายการย่อย</th>
                  </tr>
                  <br />

                  <tr>
                    <td align="center">
                      {{ new Intl.NumberFormat("en-IN").format(product.price) }}
                      บาท
                    </td>

                    <td align="center">
                      จำนวน
                      {{
                        new Intl.NumberFormat("en-IN").format(product.quantity)
                      }}
                      ชิ้น
                    </td>
                  </tr>
                  <tr>
                    <td>ยอดคำสั่งซื้อทั้งหมด:</td>
                    <td>
                      <code>
                        {{
                          new Intl.NumberFormat("en-IN").format(
                            product.total_price
                          )
                        }}
                        บาท
                      </code>
                    </td>
                  </tr>
                </table>

                ช่องทางการชำระเงิน
                <code>
                  {{ product.payment_options }}
                </code>
              </div>
            </span>
          </div>
          <div class="coral item" style="width: 200px">
            <span class="pink item" v-if="product.type === 'product'">
              <div>
                <span style="font-size: 13px">
                  <b>ที่อยู่ในการจัดส่ง</b> <br />
                  {{ product.user_address.name }}
                  || {{ product.user_address.phone }}<br />
                  ต. {{ product.user_address.district }} อ.
                  {{ product.user_address.amphoe }} จ.
                  {{ product.user_address.province }} ,{{
                    product.user_address.zipcode
                  }}
                  ||
                  {{ product.user_address.detail }}
                  <hr />
                  <div v-if="product.status === 'successful'">
                    <b-alert variant="success" show v-if="product.delivery">
                      <div class="alert-body">
                        <span>{{ product.delivery_status }}</span>
                      </div>
                    </b-alert>
                    <b-alert variant="warning" show v-else>
                      <div class="alert-body">
                        <span>{{ product.delivery_status }}</span>
                      </div>
                    </b-alert>
                    {{ product.delivery }}
                  </div>
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        <table class="center" v-if="model_show">
          <tr>
            <th id="idheard"><b>รายละเอียด </b></th>
            <th style="width: 80%">{{ product.description }}</th>
          </tr>
          <hr />
          <tr>
            <th id="idheard"><b>วิธีชำระเงิน </b></th>
            <th>{{ product.payment_options }}</th>
          </tr>
          <tr>
            <th id="idheard"><b>ผู้ชำระเงิน </b></th>
            <th>{{ product.cardName }}</th>
          </tr>
          <hr />
          <tr>
            <th id="idheard"><b>จำนวนเงิน </b></th>
            <th v-if="product.package_category === 'monthly'">
              ฿{{ product.data_detail.collect_month_price }}.00
            </th>
            <th v-else>฿{{ product.data_detail.collect_year_price }}.00</th>
          </tr>
          <tr>
            <th id="idheard"><b>ภาษีมูลค่าเพิ่ม </b></th>
            <th v-if="product.package_category === 'monthly'">
              ฿{{ product.data_detail.collect_month_vat }}.00
            </th>
            <th v-else>฿{{ product.data_detail.collect_year_vat }}.00</th>
          </tr>
          <tr>
            <th id="idheard"><b>รวมทั้งสิ้น(รวมภาษี) </b></th>
            <th v-if="product.package_category === 'monthly'">
              ฿{{ product.data_detail.collect_month_total }}.00
            </th>
            <th v-else>฿{{ product.data_detail.collect_year_total }}.00</th>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
  VBModal,
  BModal,
  BImg,
  BAlert,
} from "bootstrap-vue";
import api from "@/api";
import Ripple from "vue-ripple-directive";
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BAlert,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    BImg,
    BModal,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      amount_total: 0,
      sortBy: "",
      product_type: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "id", label: "id" },
        { key: "create_datetime", label: "วันที่ชำระเงิน" },
        { key: "description", label: "รายละเอียด" },
        { key: "mont", label: "เดือนที่ใช้" },
        { key: "total_price", label: "จำนวนเงิน(รวมภาษี)" },
        { key: "viwe", label: "รายละเอียดการชำระเงิน" },
        { key: "status", label: "สถานะ", sortable: true },
        { key: "tax", label: "ข้อมูลใบกับกำภาษี" },
      ],

      items: [],
      model_show: false,
      product: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.loaddata();
    if (this.$route.query.source) {
      this.$swal({
        title: "ชำระเงินสำเร็จ!",
        text: "สร้างรายการชำระสินค้าสำเร็จ!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }
  },
  methods: {
    loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("payment_history", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.items = response.data.data;

          this.totalRows = response.data.total;

          // var row_end = {
          //   id: "",
          //   user_id: "",
          //   amount: "ยอดรวมทั้งหมด    " + this.amount_total + ".00   บาท",
          //   transactionId: "",
          //   qrUrl: "",
          //   referenceNo: "",
          //   status: "",
          //   package: "",
          //   create_datetime: "",
          //   tax_invoice  : " จำนวน "+this.totalRows+ ' รายการ',
          // };
          // this.items.push(row_end)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    model_detail(data) {
      this.product = [];
      this.product_type = null;
      this.product_type = data.type;
      console.log("data :>> ", data);

      this.product = data;

      this.model_show = true;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
#idheard {
 /// text-align: right;
  padding-right: 1em;

  

 
}
th, td {
  padding: 5px;
  text-align: left;
  ///color: darkmagenta
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
